import axiosIns from '@axios'

const $http = axiosIns

export const $getSignTypes = async (filters: object) => {
  return await $http.get('sign-types/', { params: filters })
}

export const $createSignType = async (payload: object) => {
  return await $http.post('sign-types/', payload, { headers: { 'Content-Type': 'multipart/form-data' } })
}

export const $createSign = async (payload: object) => {
  return await $http.post('signs/', payload)
}

export const $updateSign = async (id: number | string, payload: object) => {
  return await $http.patch(`signs/${id}/`, payload)
}

export const $deleteSign = async (id: number) => {
  return await $http.delete(`signs/${id}/`)
}

export const $getSign = async (id: number) => {
  return await $http.get(`signs/${id}/`)
}

export const $signOptions = async () => {
  return await $http.options('signs/')
}

export const $getSigns = async (params: object) => {
  return await $http.get('signs/', { params: params })
}

export const $getFunctionalAreas = async (filters: object) => {
  return await $http.get('functional-areas/', { params: filters })
}

export const $multipleUpdateSignFA = async (payload: object) => {
  return await $http.post('multiple-update-sign-fa/', payload)
}

export const $multipleUpdateSignAssignee = async (payload: object) => {
  return await $http.post('multiple-update-sign-assignee/', payload)
}

export const $multipleUpdateSignType = async (payload: object) => {
  return await $http.post('multiple-update-sign-type/', payload)
}

export const $multipleUpdateSignStatus = async (payload: object) => {
  return await $http.post('multiple-update-sign-status/', payload)
}

export const $multipleUpdateSignOnHold = async (payload: object) => {
  return await $http.post('multiple-update-sign-on-hold/', payload)
}

export const $multipleUpdateSignPriority = async (payload: object) => {
  return await $http.post('multiple-update-sign-priority/', payload)
}

export const $multipleUpdateSignVisibility = async (payload: object) => {
  return await $http.post('multiple-update-sign-visibility/', payload)
}

// Update sign files via Dropbox
export const $updateSignDropboxFiles = async (id: number | string) => {
  return await $http.get(`/update-sign-files/${id}`)
}

// Approve/reject Sign
export const $approveSign = async (id: number | string, payload: object) => {
  return await $http.patch(`approve-sign/${id}`, payload)
}

export const $multipleApproveSign = async (payload: object) => {
  return await $http.post('multiple-approve-sign/', payload)
}

// Sign Blocks
export const $createSignBlock = async (payload: object) => {
  return await $http.post('sign-blocks/', payload)
}

export const $createMultipleSignBlocks = async (payload: object) => {
  return await $http.post('create-multiple-sign-blocks/', payload)
}

export const $updateSignBlockItem = async (id: number | string , payload: object) => {
  return await $http.patch(`update-sign-block-item/${id}`, payload)
}

export const $addSignBlockItems = async (payload: object) => {
  return await $http.post(`add-sign-block-items/`, payload)
}

export const $updateSignBlock = async (id: number, payload: object) => {
  return await $http.patch(`sign-blocks/${id}/`, payload)
}

export const $updateMultipleSignBlockItems = async (payload: any[]) => {
  return await $http.post(`update-multiple-sign-block-items/`, payload)
}

export const $updateMultipleSignBlocks = async (payload: any[]) => {
  return await $http.post(`update-multiple-sign-blocks/`, payload)
}

export const $deleteMultipleSignBlocks = async (payload: any) => {
  return await $http.post(`delete-multiple-sign-blocks/`, payload)
}

export const $updateMultipleSignGlossaryRows = async (payload: any[]) => {
  return await $http.post(`update-multiple-sign-glossary-rows/`, payload)
}

export const $updateSignGlossaryRow = async (id: number, payload: object) => {
  return await $http.patch(`sign-glossary-rows/${id}/`, payload)
}

export const $deleteSignGlossaryRow = async (id: number) => {
  return await $http.delete(`sign-glossary-rows/${id}/`)
}

export const $getSignBlock = async (id: number) => {
  return await $http.get(`sign-blocks/${id}/`)
}

export const $getSignBlocks = async (params: object) => {
  return await $http.get('sign-blocks/', { params: params })
}

export const $deleteSignBlock = async (id: number) => {
  return await $http.delete(`sign-blocks/${id}/`)
}

// Export Excel Signs
// Export Excel Signs from Map View
export const $exportExcelSigns = async (payload: object) => {
  return await $http.post('/export-excel-signs/', payload, { responseType: 'blob' })
}

// Export Excel Signs from Global Search View
export const $exportExcelGlobalSigns = async (payload: object) => {
  return await $http.post('/export-excel-global-signs/', payload, { responseType: 'blob' })
}

// Scoping Photos
export const $getScopingPhotos = async (params: object) => {
  return await $http.get('/scoping-photos/', { params: params })
}

export const $createScopingPhoto = async (payload: object) => {
  return await $http.post('/scoping-photos/', payload)
}

export const $deleteScopingPhoto = async (id: number | string) => {
  return await $http.delete(`/scoping-photos/${id}/`)
}

// Sign status history
export const $getSignHistory = async (params: object) => {
  return await $http.get('/sign-history/', { params: params })
}

export const $createSignsOpportunity = async (payload: object) => {
  return await $http.post('/create-signs-opportunity/', payload)
}

export const $getUserSigns = async (filters: object) => {
  return await $http.get('signs-user/', { params: filters })
}

// Sign Finances
export const $getSignsFinance = async (filters: object) => {
  return await $http.get('signs-finance/', { params: filters })
}

export const $getSignsFinanceLocations = async (filters: object) => {
  return await $http.get('signs-finance-locations/', { params: filters })
}

// Sign Chart
export const $getSignsChart = async (filters: object) => {
  return await $http.get('signs-chart/', { params: filters })
}

// Global Signs Search
export const $signsGlobalOptions = async (filters: object) => {
  return await $http.get('signs-global-options/', { params: filters })
}

// Sign tagged users list
export const $getSignTaggedUsersList = async (id: number | string) => {
  return await $http.get(`sign/${id}/tagged-users/`)
}

export const $addTaggedUserToSign = async (signId: number | string, userId: number | string) => {
  return await $http.post(`sign/${signId}/add-tagged-user/${userId}/`)
}

export const $removeTaggedUserFromSign = async (signId: number | string, userId: number | string) => {
  return await $http.delete(`sign/${signId}/remove-tagged-user/${userId}/`)
}

// Sign Activity Timeline
export const $getSignActivityTimeline = async (id: number | string) => {
  return await $http.get(`sign/${id}/activity-timeline/`)
}

// Sign Comments
export const $createSignComment = async (payload: object) => {
  return await $http.post('sign-comments/', payload)
}

export const $updateSignComment = async (id: number | string, payload: object) => {
  return await $http.patch(`sign-comments/${id}/`, payload)
}

export const $deleteSignComment = async (id: number | string) => {
  return await $http.delete(`sign-comments/${id}/`)
}

export const $getSignComment = async (id: number | string) => {
  return await $http.get(`sign-comments/${id}/`)
}

export const $getSignComments = async (params: object) => {
  return await $http.get('sign-comments/', { params: params })
}

// Signs Copy
export const $copySigns = async (payload: object) => {
  return await $http.post('copy-signs/', payload)
}

// Offline Signs
export const $createOfflineItems = async (payload: object) => {
  return await $http.post('create-offline-signs/', payload)
}
