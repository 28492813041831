<script setup>
import { $updateSignGlossaryRow, $deleteSignGlossaryRow } from '@/api/signs'

const props = defineProps({
  modelValue: Boolean,
  signGlossaryRow: { type: Object, default: () => ({}) },
})

const emit = defineEmits(['update:modelValue', 'update:signGlossaryRow', 'onSubmit', 'onClose'])

const closeDialog = () => {
  emit('update:modelValue', false)
}

const handleAction = async (action) => {
  // If there is no data or no ID - close the dialog
  if (!props.signGlossaryRow || !props.signGlossaryRow.id) {
    closeDialog()
    return
  }

  try {
    if (action === 'update') {
      const { bottom_divider } = props.signGlossaryRow
      await $updateSignGlossaryRow(props.signGlossaryRow.id, { bottom_divider })
    } else if (action === 'delete') {
      await $deleteSignGlossaryRow(props.signGlossaryRow.id)
    }

    emit('onSubmit')
    closeDialog()
  } catch (e) {
    console.log(e)
  }
}

const onUpdate = () => handleAction('update')
const onDelete = () => handleAction('delete')

const onClose = async () => {
  emit('onClose')
}
</script>

<template>
  <VDialog
    :model-value="modelValue"
    max-width="680"
    class="default-dialog"
    @after-leave="onClose"
    @update:modelValue="emit('update:modelValue')"
  >
    <VBtn
      variant="elevated"
      density="compact"
      icon="tabler-x"
      class="v-dialog-close-btn"
      @click="closeDialog"
    />

    <VCard :title="`Sign Glossary Row ${signGlossaryRow?.position_number}`">
      <VCardText>
        <VRow>
          <VCol
            cols="12"
            sm=""
          >
            <VCheckbox
              v-model="signGlossaryRow.bottom_divider"
              label="Bottom Divider"
              class="text-uppercase font-weight-bold"
              :class="{ 'shadow-bottom-divider': !!signGlossaryRow.bottom_divider }"
            />
          </VCol>
        </VRow>
        <VRow>
          <VCol
            class="d-flex justify-end"
            cols="12"
          >
<!--            <VBtn-->
<!--              variant="outlined"-->
<!--              append-icon="mdi-minus-circle"-->
<!--              @click="onDelete"-->
<!--            >-->
<!--              Delete-->
<!--            </VBtn>-->
            <VBtn
              @click="onUpdate"
            >
              Update
            </VBtn>
          </VCol>
        </VRow>
      </VCardText>
    </VCard>
  </VDialog>
</template>

<style lang="scss" scoped>
.shadow-bottom-divider {
  box-shadow: 0 10px 0 0 rgba(var(--v-border-color), var(--v-border-opacity));
}
</style>
