<script setup>
import VuePdfEmbed from 'vue-pdf-embed'
import { useDrawingStore } from '@/store/drawing'
import { useUserStore } from '@/store/user'
import { useSnackbarStore } from '@/store/snackbar'
import { $updateKopItem, $getKopItem, $getKopItemOptions, $approveKopItem } from '@/api/kop'
import { $getCampaign } from '@/api/campaigns'

const props = defineProps({
  kopItemDialogVisible: { type: Object, default: () => ({}) },
  kopItemList: { type: Boolean, default: false },
  selectedKopItemId: { type: [String, Number], default: null },
})

const emit = defineEmits(['update:kopItemDialogVisible', 'onUpdate'])

const computedKopItemDialogVisible = computed({
  // getter
  get() {
    return props.kopItemList ? props.kopItemDialogVisible : drawingStore.kopItemDialogVisible
  },
  // setter
  set(newValue) {
    if (props.kopItemList) {
      emit('update:kopItemDialogVisible', newValue)
    } else {
      drawingStore.kopItemDialogVisible = newValue
    }
  }
})

const computedKopItemId = computed(() => {
  return props.kopItemList ? props.selectedKopItemId : drawingStore.selectedItem?.kop_item
})

const computedOfflineMode = computed(() => {
  return props.kopItemList ? false : drawingStore.offlineMode
})

const currentKopItemProject = ref(null)
const currentKopItemFuncAreas = ref([])

const computedFuncAreas = computed(() => {
  return props.kopItemList ? currentKopItemFuncAreas.value : drawingStore.funcAreas
})

const router = useRouter()
const route = useRoute()

const dialogVisible = ref(false)
const drawingStore = useDrawingStore()
const userStore = useUserStore()
const snackbarStore = useSnackbarStore()

const kopItem = ref({})
const kopItemLoading = ref(false)
const kopItemPriorityOptions = ref([])

const approveBtnLoading = ref(false)
const rejectBtnLoading = ref(false)
const updateBtnLoading = ref(false)

const photoTab = ref(0)

const artworkPdf = ref()
const artworkLoading = ref(true)
const page = ref(1)
const totalPages = ref(1)

const sides = ['A', 'B', 'C', 'D']
const kopItemSides = ref(1)

const showSidebar = ref(false)

const showKopItemActivityQuery = computed(() => {
  return route.query['show-kop-item-activity']
})

const updateDrawingStoreItem = (data) => {
  const index = drawingStore.items.findIndex(i => i.kop_item === data?.id)
  drawingStore.items[index].kop_item_status = data?.item_status
  drawingStore.items[index].kop_item_type = data?.kop_item_type
  drawingStore.items[index].kop_item_functional_area = data?.functional_area
  drawingStore.items[index].kop_item_code = data?.kop_item_code
  drawingStore.items[index].kop_item_short_code = data?.short_code
  drawingStore.items[index].kop_item_qty = data?.qty
  drawingStore.items[index].is_released = data?.is_released
  drawingStore.items[index].is_can_user_approve = data?.is_can_user_approve
}

const getKopItemOptions = async () => {
  try {
    const res = await $getKopItemOptions()
    kopItemPriorityOptions.value = res.data.actions.POST.priority.choices
  } catch (e) {
    console.log(e)
  }
}

const getKopItemProject = async () => {
  try {
    const res = await $getCampaign(kopItem.value.campaign)
    currentKopItemProject.value = res.data
    currentKopItemFuncAreas.value = res.data.functional_area_list
  } catch (e) {
    console.log(e)
  }
}

const onSelectPriority = async (priorityValue) => {
  if (priorityValue !== kopItem.value.priority) {
    kopItemLoading.value = true
    try {
      const res = await $updateKopItem(kopItem.value.id, { priority: priorityValue })
      kopItem.value = res.data
      // No need to update Store Item
      snackbarStore.showMessage({
        color: 'success',
        icon: 'mdi-checkbox-marked-circle',
        title: 'Success',
        text: 'KOP Item Priority updated successfully!'
      })
    } catch (e) {
      console.log(e)
      const mes = e.response.data ? e.response.data : e.message
      snackbarStore.showMessage({
        color: 'error',
        icon: 'mdi-alert-circle',
        title: 'Error',
        text: `Oops, something went wrong. ${mes}`
      })
    } finally {
      kopItemLoading.value = false
    }
  }
}

const updateKopItemIsOnHold = async () => {
  kopItemLoading.value = true
  try {
    const res = await $updateKopItem(kopItem.value.id, { is_on_hold: kopItem.value.is_on_hold })
    kopItem.value = res.data

    // Update drawing on map page
    if (!props.kopItemList) {
      // Updating store item after KopItem On Hold update
      updateDrawingStoreItem(res.data)

      // clear selected kopItems from sidebar because one of selected kopItem may become unavailable to approve/reject
      // after KopItem On Hold update
      drawingStore.selectedKopItemIds = []
    }

    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: 'On Hold option updated successfully!'
    })
  } catch (e) {
    console.log(e)
    const mes = e.response.data ? e.response.data : e.message
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: `Oops, something went wrong. ${mes}`
    })
  } finally {
    kopItemLoading.value = false
  }
}

const getKopItem = async () => {
  kopItemLoading.value = true
  try {
    if (computedKopItemId.value) {
      const res = await $getKopItem(computedKopItemId.value)
      kopItem.value = res.data
    }
  } catch (e) {
    console.log(e)
  } finally {
    kopItemLoading.value = false
  }
}

const updateKopItem = async () => {
  updateBtnLoading.value = true
  try {
    const res = await $updateKopItem(kopItem.value.id, kopItem.value)
    kopItem.value = res.data

    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: 'KOP Item updated successfully!'
    })

    // Update drawing on map page
    if (!props.kopItemList) {
      // Updating store item after updateKopItem
      updateDrawingStoreItem(res.data)
      await drawingStore.saveItems()
    }
    // Close dialog and emit onUpdate on Kop Item List
    else {
      emit('onUpdate')
      closeDialog()
    }
  } catch (e) {
    console.log(e)
  } finally {
    updateBtnLoading.value = false
  }
}

const approveKopItem = async (isApprove = true) => {
  if (isApprove) {
    approveBtnLoading.value = true
  } else {
    rejectBtnLoading.value = true
  }
  try {
    await $approveKopItem(kopItem.value.id, { is_approve: isApprove })
    snackbarStore.showMessage({
      color: 'success',
      icon: 'mdi-checkbox-marked-circle',
      title: 'Success',
      text: 'KOP Item updated successfully!'
    })
    await getKopItem()
    // Update drawing on map page
    if (!props.kopItemList) {
      // Updating store item after updateKopItem
      updateDrawingStoreItem(kopItem.value)
    }
    // Emit onUpdate on Kop Item List
    else {
      emit('onUpdate')
    }
  } catch (e) {
    const mes = e.response.data?.message ? e.response.data?.message : e.response.data
    snackbarStore.showMessage({
      color: 'error',
      icon: 'mdi-alert-circle',
      title: 'Error',
      text: `Oops, something went wrong. ${mes}`
    })
  } finally {
    if (isApprove) {
      approveBtnLoading.value = false
    } else {
      rejectBtnLoading.value = false
    }
  }
}

const rejectKopItem = async () => {
  await approveKopItem(false)
}

const onCloseDialog = () => {
}

const handleArtworkRendered = () => {
  artworkLoading.value = false
  totalPages.value = artworkPdf.value.pageCount
}

const closeDialog = () => {
  computedKopItemDialogVisible.value = false
}

const handleKeydown = (e) => {
  // Close the dialog if the ESC key is pressed
  if (e.key === 'Escape' && !!computedKopItemDialogVisible.value) {
    closeDialog()
  }
}

onMounted(() => {
  window.addEventListener('keydown', handleKeydown)
  getKopItem()
})

// Cleanup event listener when component unmounts
onBeforeUnmount(() => {
  window.removeEventListener('keydown', handleKeydown)
})

// Reusable function to silently update the query without causing a page reload, onMounted and navigation guard methods
const updateQueryParameters = (updateFunction) => {
  // Create a copy of the route query parameters
  const newQuery = { ...route.query }

  // Call the provided update function to modify the query
  updateFunction(newQuery)

  // Get the current path without the query string
  const path = window.location.pathname

  // Build the new URL with the updated query parameters
  const newUrl = `${path}?${new URLSearchParams(newQuery).toString()}`

  // Replace the current state with the updated URL
  window.history.replaceState(null, '', newUrl)
}

// Function to delete the 'show-kopItem-activity' query parameter
const deleteShowKopItemActivityQuery = () => {
  updateQueryParameters((newQuery) => {
    delete newQuery['show-kop-item-activity']
  })
}

// Function to set the 'kopItem' query parameter
const setKopItemQuery = () => {
  updateQueryParameters((newQuery) => {
    newQuery['kop-item'] = computedKopItemId.value
  })
}

// Function to clear the 'kopItem' query parameter
const clearKopItemQuery = () => {
  updateQueryParameters((newQuery) => {
    delete newQuery['kop-item']
  })
}

watch(() => computedKopItemDialogVisible.value, async () => {
  // on show Dialog
  if (!!computedKopItemDialogVisible.value) {
    setKopItemQuery()

    await getKopItem()
    await getKopItemOptions()

    if (props.kopItemList) {
      await getKopItemProject()
    }

    if (showKopItemActivityQuery.value === 'true') {
      // Show KopItem Activity sidebar after KopItem Modal fully loads
      await nextTick(() => {
        showSidebar.value = true // Set sidebar to be visible
        deleteShowKopItemActivityQuery() // Remove any existing queries for showing KopItem Activity
      })
    }
  }
  // on hide Dialog
  else {
    clearKopItemQuery()
  }
})
</script>

<template>
  <div>
    <VDialog
      v-model="computedKopItemDialogVisible"
      class="kop-item-modal"
      persistent
      width="920"
      @after-leave="onCloseDialog"
      @update:model-value="getKopItem"
    >

      <DialogCloseBtn
        @click="closeDialog"
      />

      <VCard
        :loading="!!kopItemLoading"
      >
        <template #title>
          <KopItemDialogTitle
            v-model:show-sidebar="showSidebar"
            :approve-btn-loading="!!approveBtnLoading"
            :reject-btn-loading="!!rejectBtnLoading"
            :kop-item="kopItem"
            @onApproveKopItem="approveKopItem"
            @onRejectKopItem="rejectKopItem"
          />
        </template>
        <KopItemDialogHeader
          v-model:kop-item="kopItem"
          :kop-item-loading="kopItemLoading"
          :kop-item-priority-options="kopItemPriorityOptions"
          @onSelectPriority="onSelectPriority"
          @onUpdateKopItemIsOnHold="updateKopItemIsOnHold"
        />
        <KopItemDialogResponsibleBlock
          :kop-item="kopItem"
        />
        <VRow>
          <VCol
            :md="computedOfflineMode ? 12 : 7"
            cols="12">
            <VCardText>
              <VRow>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VLabel>KOP Item Type:</VLabel>
                  <br/>
                  <b>{{ kopItem.kop_item_type_info?.name }}</b>
                </VCol>
                <VCol
                  cols="12"
                  sm="8"
                >
                  <VSelect
                    v-model="kopItem.functional_area"
                    :items="[...computedFuncAreas]"
                    :disabled="userStore.isCustomer"
                    item-value="id"
                    item-title="name"
                    label="Functional Area"
                    no-data-text="Functional Areas not found"
                    density="compact"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VTextField
                    v-model="kopItem.width"
                    :disabled="userStore.isCustomer"
                    label="Width"
                    type="number"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VTextField
                    v-model="kopItem.height"
                    :disabled="userStore.isCustomer"
                    label="Height"
                    type="number"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="4"
                >
                  <VTextField
                    v-model="kopItem.qty"
                    :disabled="userStore.isCustomer"
                    label="Qty"
                    type="number"
                  />
                </VCol>
              </VRow>
            </VCardText>
            <VCardText>
              <VRow>
                <VCol
                  cols="12"
                  sm="6"
                >
                  <VTextarea
                    v-model="kopItem.notes"
                    :disabled="userStore.isCustomer"
                    label="TLC Notes"
                  />
                </VCol>
                <VCol
                  cols="12"
                  sm="6"
                >
                  <VTextarea
                    v-model="kopItem.client_notes"
                    :disabled="!userStore.isCustomer"
                    label="Client Notes"
                  />
                </VCol>
              </VRow>
            </VCardText>
          </VCol>
          <VCol
            v-if="!computedOfflineMode"
            cols="12"
            md="5"
          >
            <VTabs v-model="photoTab">
              <VTab>Artwork</VTab>
              <VTab>Photos</VTab>
            </VTabs>
            <VWindow v-model="photoTab">
              <VWindowItem class="pa-1 h-100">
                <template v-if="!kopItem.artwork_link">
                  <div class="pa-2 text-center">
                    No Artwork
                  </div>
                </template>
                <template v-else>
                  <VOverlay
                    v-model="artworkLoading"
                    class="align-center justify-center"
                    contained
                    persistent
                  >
                    <VProgressCircular
                      color="primary"
                      indeterminate
                    />
                  </VOverlay>
                  <VuePdfEmbed
                    ref="artworkPdf"
                    :page="page"
                    :source="kopItem.artwork_link"
                    @rendered="handleArtworkRendered"
                  />
                  <div class="pdf-pages">
                    <template v-for="side in kopItemSides + 1">
                      <a :class="{ 'active': side  === page }"
                         class="pdf-page" @click="page = side"
                      >
                        {{ sides[side - 1] }}
                      </a>
                    </template>
                  </div>
                </template>
              </VWindowItem>
              <VWindowItem>
                <div
                  class="mt-2 pl-2 pl-md-0"
                  style="height: 300px; overflow: visible;"
                >
                  <ScopingPhotosBlock
                    kop
                    :kop-item-id="computedKopItemId"
                    :can-add="true"
                    :can-delete="true"
                  />
                </div>
              </VWindowItem>
            </VWindow>
          </VCol>
        </VRow>
        <VCardText>
          <div class="d-flex justify-space-between align-center flex-wrap">
            <div class="d-flex flex-wrap">
              <VBtn
                :disabled="!!updateBtnLoading"
                class="mr-2 mt-2 mt-sm-0"
                @click="updateKopItem"
              >
                Update
                <VProgressCircular
                  v-if="!!updateBtnLoading"
                  class="ml-1"
                  color="light"
                  indeterminate
                  size="16"
                  width="2"
                />
              </VBtn>
              <!-- Delete available only on the map view not list view -->
              <VBtn
                v-if="!kopItemList"
                class="mt-2 mt-sm-0"
                variant="tonal"
                @click="drawingStore.deleteSelectedItem(); closeDialog();"
              >
                Delete
              </VBtn>
            </div>
          </div>
        </VCardText>
      </VCard>
    </VDialog>
  </div>
</template>

<style lang="scss">
.kop-item-modal {
  .v-card {
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px !important;
  }

  .ghost-draggable {
    opacity: 0.5;
    background: rgb(var(--v-theme-primary), 0.2);
  }

  .add-pictogram-block {
    &:hover {
      background-color: rgb(var(--v-theme-on-surface), 0.1);
    }
  }
}
.pdf-item {
  padding: 24px;
  width: 100%;
  height: 100%;
  min-height: 500px;
  background: #fff;
  color: #000;
}

.pdf-pages {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .pdf-page {
    cursor: pointer;
    padding: 2px 8px;
    margin: 0 2px;
    border: 1px solid #DC0000;
    border-radius: 50%;
    &.active {
      background: #DC0000;
      color: #fff;
    }
  }
}
</style>
