import axiosIns from '@axios'

const $http = axiosIns

export const $getCountries = async (filters: object) => {
    return await $http.get('/countries/', {params: filters})
}

export const $createCountry = async (payload: object) => {
    return await $http.post('/countries/', payload)
}

export const $deleteCountry = async (id: number) => {
    return await $http.delete(`/countries/${id}/`)
}

export const $updateCountry = async (id: number, payload: object) => {
    return await $http.patch(`/countries/${id}/`, payload)
}

export const $getCities = async (filters: object) => {
    return await $http.get('/cities/', {params: filters})
}

export const $createCity = async (payload: object) => {
    return await $http.post('/cities/', payload)
}

export const $deleteCity = async (id: number) => {
    return await $http.delete(`/cities/${id}/`)
}

export const $updateCity = async (id: number, payload: object) => {
    return await $http.patch(`/cities/${id}/`, payload)
}


export const $getSignTextGroups = async (filters: object) => {
    return await $http.get('/sign-text-groups/', {params: filters})
}

export const $createSignTextGroup = async (payload: object) => {
    return await $http.post('/sign-text-groups/', payload)
}

export const $deleteSignTextGroup = async (id: number) => {
    return await $http.delete(`/sign-text-groups/${id}/`)
}

export const $updateSignTextGroup = async (id: number, payload: object) => {
    return await $http.patch(`/sign-text-groups/${id}/`, payload)
}

export const $getSignTexts = async (filters: object) => {
    return await $http.get('/sign-texts/', { params: filters })
}

export const $createSignTexts = async (payload: object) => {
    return await $http.post('/sign-texts/', payload)
}

export const $deleteSignTexts = async (id: number) => {
    return await $http.delete(`/sign-texts/${id}/`)
}

export const $updateSignTexts = async (id: number, payload: object) => {
    return await $http.patch(`/sign-texts/${id}/`, payload)
}

export const $uploadSignTexts = async (payload: object) => {
    return await $http.post('/glossary-upload/', payload)
}

export const $multipleDeleteSignTexts = async (payload: object) => {
    return await $http.post('/multiple-delete-sign-texts/', payload)
}

// Sign Pictogram Catalogues
export const $getSignPictogramCatalogues = async (filters: object) => {
    return await $http.get('/sign-pictogram-catalogues/', { params: filters })
}

export const $createSignPictogramCatalogue = async (payload: object) => {
    return await $http.post('/sign-pictogram-catalogues/', payload)
}

export const $deleteSignPictogramCatalogue = async (id: number) => {
    return await $http.delete(`sign-pictogram-catalogues/${id}/`)
}

export const $updateSignPictogramCatalogue = async (id: number, payload: object) => {
    return await $http.patch(`sign-pictogram-catalogues/${id}/`, payload)
}

// Sign Type Catalogues
export const $getSignCatalogues = async (filters: object) => {
    return await $http.get('/sign-catalogues/', { params: filters })
}

export const $createSignCatalogue = async (payload: object) => {
    return await $http.post('/sign-catalogues/', payload)
}

export const $deleteSignCatalogue = async (id: number) => {
    return await $http.delete(`/sign-catalogues/${id}/`)
}

export const $updateSignCatalogue = async (id: number, payload: object) => {
    return await $http.patch(`/sign-catalogues/${id}/`, payload)
}

export const $copySignTypeCatalogue = async (payload: object) => {
    return await $http.post('/copy-sign-type-catalogue/', payload)
}

export const $getSignTypes = async (filters: object) => {
    return await $http.get('/sign-types/', { params: filters })
}

export const $createSignType = async (payload: object) => {
    return await $http.post('/sign-types/', payload)
}

export const $deleteSignType = async (id: number) => {
    return await $http.delete(`/sign-types/${id}/`)
}

export const $updateSignType = async (id: number, payload: object) => {
    return await $http.patch(`/sign-types/${id}/`, payload)
}

export const $getFunctionalAreas = async (filters: object) => {
    return await $http.get('/functional-areas/', { params: filters })
}

export const $createFunctionalArea = async (payload: object) => {
    return await $http.post('/functional-areas/', payload)
}

export const $deleteFunctionalArea = async (id: number) => {
    return await $http.delete(`/functional-areas/${id}/`)
}

export const $updateFunctionalArea = async (id: number, payload: object) => {
    return await $http.patch(`/functional-areas/${id}/`, payload)
}

export const $getEventGroups = async (filters: object) => {
    return await $http.get('/event-groups/', { params: filters })
}

export const $createEventGroup = async (payload: object) => {
    return await $http.post('/event-groups/', payload)
}

export const $deleteEventGroup = async (id: number) => {
    return await $http.delete(`/event-groups/${id}/`)
}

export const $updateEventGroup = async (id: number, payload: object) => {
    return await $http.patch(`/event-groups/${id}/`, payload)
}

export const $getSignTypeOptions = async () => {
    return await $http.options('/sign-types/')
}
