<script setup lang="ts">
import Vue3Html2pdf from 'vue3-html2pdf'
import { useDrawingStore } from '@/store/drawing'
import { Sign } from '@/models/sign'
import { ActivityTimelineItem } from '@/models/activity-timeline'
import VuePdfEmbed from 'vue-pdf-embed'

interface IProps {
  sign?: Sign,
  printLoading?: boolean,
  activityTimelineItems?: ActivityTimelineItem[],
}

const props = withDefaults(defineProps<IProps>(), {
  sign: () => ({}),
  printLoading: false,
  activityTimelineItems: () => ([]),
})

interface CustomEvents {
  (e: 'update:printLoading', value: boolean): void
}

const emit = defineEmits<CustomEvents>()

const drawingStore = useDrawingStore()

const htmlPdf = ref()

const tabs = computed(() => {
  const result = []

  if (props.sign && props.sign?.type_info && (props.sign?.type_info?.sides || props.sign?.type_info?.sides === 0)) {
    for (let i = 0; i <= props.sign?.type_info?.sides; i++) {
      result.push(i)
    }
  }

  return result
})

const maxDesignPdfHeight = computed(() => {
  return tabs.value.length <= 2 ? '250px' : '140px'
})

const sides = ['A', 'B', 'C', 'D']

const onExportPdf = async (): Promise<void> => {
  const el: HTMLElement | null = document.getElementById('mapSnapshot')

  if (!el) {
    console.error('Element with id "mapSnapshot" not found')
    return
  }

  if (!drawingStore.stage || !drawingStore.stage.getStage()) {
    console.error('Stage not found')
    return
  }

  el.innerHTML = ''

  try {
    // Retrieve the Konva stage and make deep copies of the current scale and position
    const stage = drawingStore.stage.getStage()
    const currentScale = JSON.parse(JSON.stringify(drawingStore.scale.x)) // deep copy current stage Scale
    const currentPositionX = JSON.parse(JSON.stringify(stage.x())) // deep copy current stage Position X
    const currentPositionY = JSON.parse(JSON.stringify(stage.y())) // deep copy current stage Position Y

    // Get the PDF wrapper element and its dimensions
    const pdfWrapper = document.getElementById('map-wrapper')
    let pdfWrapperWidth = 0
    let pdfWrapperHeight = 0
    if (pdfWrapper) {
      pdfWrapperWidth = pdfWrapper.offsetWidth
      pdfWrapperHeight = pdfWrapper.offsetHeight
    }

    // Assuming drawingStore.bgImg.width and drawingStore.bgImg.height are the image dimensions
    const imageWidth = drawingStore.bgImg.width
    const imageHeight = drawingStore.bgImg.height

    // Calculate the scaling factors for width and height
    const widthScale = pdfWrapperWidth / imageWidth
    const heightScale = pdfWrapperHeight / imageHeight

    // Use the smaller scaling factor to ensure that the entire image fits within the container(pdfWrapper)
    const scale = Math.min(widthScale, heightScale) * 1.4

    // Apply the calculated scale and reset the position to (0, 0) for correct Map display
    stage.scale({x: scale, y: scale})
    stage.position({ x: 0, y: 0 })

    const mapSnapshot: HTMLImageElement = new Image()
    mapSnapshot.crossOrigin = 'Anonymous'
    mapSnapshot.src = stage.toDataURL({
      x: drawingStore.selectedItem.x * scale - 100,
      y: drawingStore.selectedItem.y * scale - 75,
      width: 250,
      height: 200,
      quality: 1,
      pixelRatio: 15,
      imageSmoothingEnabled: false
    })

    // Return to base size with better quality(with increased pixelRatio)
    mapSnapshot.width = 250
    mapSnapshot.height = 200

    // Update PDF with Map Snapshot
    el.appendChild(mapSnapshot)

    // Wait for the next tick to restore the stage to its original scale and position
    await nextTick(() => {
      stage.scale({x: currentScale, y: currentScale})
      stage.position({x: currentPositionX, y: currentPositionY})
    })
  } catch (e) {
    console.log(e)
  }
}

const useGeneratePdf = async () => {
  emit('update:printLoading', true)
  try {
    // Add Map Snapshot
    await onExportPdf()

    // Generate PDF
    htmlPdf.value.generatePdf()
  } catch (e) {
    console.log(e)
  } finally {
    emit('update:printLoading', false)
  }
}

defineExpose({ useGeneratePdf })
</script>

<template>
  <vue3-html2pdf
    ref="htmlPdf"
    :enable-download="true"
    :paginate-elements-by-height="1000"
    :show-layout="false"
    pdf-content-width="100%"
    pdf-orientation="landscape"
  >
    <template  v-slot:pdf-content>
      <section
        class="pdf-item"
        :style="`--max-design-pdf-height: ${maxDesignPdfHeight}`"
      >
        <VChip color="primary" variant="outlined">
          {{ sign?.sign_status_name }}
        </VChip>
        <table class="pdf-table">
          <tr>
            <td width="30%">
              <h4>Project</h4>
              {{ drawingStore?.project?.name }}
            </td>
            <td width="20%">
              <h4>Client</h4>
              {{ drawingStore?.project?.customer_name }}
            </td>
            <td>
              <h4>FA Approver(s)</h4>
              <template v-if="sign?.responsible_list?.length">
                <div
                  v-for="(responsible, responsibleKey) in sign?.responsible_list"
                  :key="responsibleKey"
                >
                  {{ responsible?.first_name }} {{ responsible?.last_name }}
                </div>
              </template>
              <template v-else>
                {{ sign?.responsible_name }}
              </template>
            </td>
          </tr>
          <tr>
            <td>
              <h4>Sign Number</h4>
              <span class="text-primary text-h3">{{ sign?.short_name }}</span>
            </td>
            <td>
              <h4>Location</h4>
              {{ drawingStore?.project?.location_name }}
            </td>
            <td>
              <h4>Sub-Location</h4>
              {{ drawingStore?.selectedLocation ? drawingStore?.selectedLocation?.name : '-' }}
            </td>
          </tr>
          <tr>
            <td v-if="drawingStore?.stage?.getStage()">
              <h4>Map Snapshot</h4>
              <div id="mapSnapshot"></div>
            </td>
            <td
              :colspan="drawingStore?.stage?.getStage() ? 1 : 2"
            >
              <template
                v-if="sign?.artwork_link"
              >
                <h4>Design</h4>
                <VRow style="max-height: 380px">
                  <VCol
                    v-for="(side, sideKey) in tabs"
                    :key="sideKey"
                    :cols="tabs.length === 1 ? 12 : 6"
                  >
                    <h4 class="text-center">
                      {{ sides[sideKey] }}
                    </h4>
                    <div class="d-flex justify-center align-center design-pdf-wrapper">
                      <VuePdfEmbed
                        ref="artworkPdf"
                        class="design-pdf"
                        :page="side + 1"
                        :source="sign.artwork_link"
                      />
                    </div>
                  </VCol>
                </VRow>
              </template>
              <template v-else>
                <h4>Cost</h4>
                {{ sign.sign_price }}
              </template>
            </td>
            <td>
              <h4>Content (glossary and picto-gram)</h4>
              <VRow>
                <VCol
                  v-for="(side, sideKey) in tabs"
                  :key="sideKey"
                  :cols="tabs.length === 1 ? 12 : 6"
                >
                  <h4 class="text-center">
                    {{ sides[sideKey] }}
                  </h4>

                  <div
                    v-for="(row, rowKey) in sign?.sign_glossary_rows.filter(i => i.side_number === side)"
                    :key="rowKey"
                  >
                    <div
                      v-for="(block, blockKey) in row?.sign_blocks"
                      :key="blockKey"
                    >
                      <div v-if="block.text_1">
                        <span>{{ block.text_1 }}</span>
                      </div>
                      <div v-if="block.text_2">
                        <span>{{ block.text_2}}</span>
                      </div>
                      <div v-if="block.pic_1_name" class="sign-pic">
                        <span>Pic: {{ block.pic_1_name }}</span>
                      </div>
                      <div v-if="block.pic_2_name" class="sign-pic">
                        <span>Pic: {{ block.pic_2_name }}</span>
                      </div>
                    </div>
                  </div>
                </VCol>
              </VRow>
            </td>
          </tr>
          <tr>
            <td>
              <h4>History</h4>
              <div
                v-for="(activityTimelineItem, activityTimelineItemKey) in props.activityTimelineItems.slice(-5)"
                :key="activityTimelineItemKey"
                class="history-record"
              >
                <span class="mr-1">{{ activityTimelineItem.activity_time }}</span>
                <span>{{ activityTimelineItem.title }}</span>
                <div class="history-record__content">{{ activityTimelineItem?.content }}</div>
              </div>
            </td>
            <td>
              <h4>Annotation or comments</h4>
              {{ sign?.notes }}
            </td>
            <td>
              <h4>Approval Signature(s)</h4>
              <template v-if="sign?.responsible_list?.length">
                <div
                  v-for="(responsible, responsibleKey) in sign?.responsible_list"
                  :key="responsibleKey"
                  class="mb-3"
                >
                  <div class="approval-signature-line">
                    <span>{{ responsible?.first_name }} {{ responsible?.last_name }}</span>
                    <span class="approval-signature-underline"></span>
                  </div>
                  <div class="approval-signature-line">
                    <span>Date:</span>
                    <span class="approval-signature-underline"></span>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="approval-signature-line">
                  <span>{{ sign?.responsible_name }}</span>
                  <span class="approval-signature-underline"></span>
                </div>
                <div class="approval-signature-line">
                  <span>Date:</span>
                  <span class="approval-signature-underline"></span>
                </div>
              </template>
            </td>
          </tr>
        </table>
      </section>
    </template>
  </vue3-html2pdf>
</template>

<style lang="scss">
.pdf-table {
  margin-top: 12px;
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse;
  td {
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 8px 12px;
    vertical-align: top;
  }
  .sign-pic {
    img {
      width: 30px;
      height: 30px;
    }
  }
}

.design-pdf-wrapper {
  max-height: var(--max-design-pdf-height);
  max-width: 100%;
  .design-pdf {
    max-height: var(--max-design-pdf-height);
    max-width: 100%;
  }
  .vue-pdf-embed canvas {
    max-height: var(--max-design-pdf-height) !important;
    max-width: 100% !important;
    width: unset !important;
    height: unset !important;
  }
}

.history-record {
  font-size: 12px;
  .history-record__content {
    font-size: 10px;
  }
}

.approval-signature-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 4px;
}

.approval-signature-underline {
  flex-grow: 1;
  border-bottom: 1px solid black;
  margin-left: 10px;
  height: 14px;
}
</style>
